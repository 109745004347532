import ReactPixel from 'react-facebook-pixel';

const options = {
  autoConfig: true,
  debug: false,
};

const initFacebookPixel = () => {
  ReactPixel.init('1267607614338043', {}, options);
  ReactPixel.pageView(); // Envoie un événement "PageView" dès le chargement
};

export default initFacebookPixel;


